import { ArrowLeftIcon } from '@heroicons/react/24/outline'

import { navigate, routes } from '@redwoodjs/router'

import Button from '../Library/Button/Button'

import NewCustomerIntegrationForm from './CreateCustomerIntegrationForm'
import CustomerIntegrationsHeader from './CustomerIntegrationsHeader'

const CustomerIntegrationsCreate = () => {
  return (
    <>
      <CustomerIntegrationsHeader hideButton />
      <div>
        <Button
          variant="text"
          fullWidth={false}
          onClick={() => navigate(routes.customerIntegrations())}
          startIcon={<ArrowLeftIcon className="h-4 w-4" />}
          className="px-4 p-2 text-gray-600 bg-gray-200 hover:bg-gray-300"
        >
          Back
        </Button>

        <hr className="my-8" />

        <NewCustomerIntegrationForm />
      </div>
    </>
  )
}

export default CustomerIntegrationsCreate
